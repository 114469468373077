import React from 'react'
import '../Footer/Footer.css'

function Footer() {
  return (
    <footer className="bottom">
        <div className="matka-container">
          <nav className="matka-nav">
            {/* <a href="#guessing" style={{color:'#800080'}}>Matka Guessing</a> | */}
            {/* <a href="#chart"style={{color:'#B8860B'}}>Matka Chart</a> |<a href="#tara"style={{color:'#0000FF'}}>Tara Matka</a> | */}
            {/* <a href="#indian"style={{color:'#008000'}}>Indian Matka</a> |<a href="#fix"style={{color:'#B8860B'}}>Fix Matka</a> | */}
            <a href="#sitemap"style={{color:'#FF0000'}}>kalyan 124</a>
          </nav>
          <div className="matka-content">
            <p>ALL RIGHTS RESERVED </p>
            <p>(2012-2024)</p>
            <p>Contact</p>
            <p className="matka-title" style={{color:'#FF0000',fontSize:'20px',fontWeight:'700'}}>Matka Website</p>
            {/* <p className="matka-phone">07404972449</p> */}
            <a href="" className="matka-link">
            (Astrologer-kalyan 124)
            </a>
          </div>
        </div>
      </footer>
  )
}

export default Footer
